.nn {
  position: fixed;
  width: 100%;
  top: 0;
  background-color: #5d1a94;
  z-index: 999;
}
.active {
  /* background-color: white; */
  top: 0;
}
.containerh {
  width: 100%;
}
.col1 {
  display: flex;
}
.col2 {
  display: contents;
}
.disactive {
  display: none;
}
.logo {
  cursor: pointer;
  display: flex;
  margin-top: 0.5rem;
  margin-left: -10px;
}
.logo img {
  height: 3rem;
}
#baricon {
  visibility: hidden;
  margin-top: 22px;
  height: 25px;
  margin-left: 20px;
  cursor: pointer;
}
.nvegation {
  margin-top: 20px;
}
.linkcolor {
  color: white;
}
.disactivecolor {
  color: white;
}
.singinli {
  font-weight: 700;
  font-family: Arial, Helvetica, sans-serif;
  cursor: pointer;
}
.singinli:hover {
  color: var(--main-color);
  text-decoration: none;
}
.bli {
  margin-top: -6px;
}
@media screen and (max-width: 1280px) {
  .ll {
    display: none;
  }
  #baricon {
    visibility: visible;
  }
  .logo {
    margin-left: 5px;
  }
}
@media screen and (max-width: 1100px) {
  .ll {
    display: none;
  }
}
@media screen and (max-width: 930px) {
  .sll {
    display: none;
  }
}
@media screen and (max-width: 700px) {
  .tli {
    display: none;
  }
  .slider {
    margin-top: 5px;
  }
  #baricon {
    color: rgb(58, 57, 57);
  }
  .disactivecolor {
    color: rgb(56, 54, 54);
  }
  .disactive {
    display: block;
  }
  .disactive img {
    height: 30px;
    margin-top: 19px;
  }
  .nm {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .singinli {
    display: none;
  }
}
@media screen and (max-width: 580px) {
  .disactive img {
    height: 30px;
    margin-top: 19px;
    position: absolute;
    right: 20%;
  }
}
@media screen and (max-width: 510) {
  .disactive img {
    right: -23px;
  }
  .logo img {
    display: block;
  }
}

.userimg {
  margin-left: 15px;
  border-radius: 50%;
}
.icon {
  margin-left: 15px;
  cursor: pointer;
  position: relative;
}

.iconImg {
  width: 20px;
  height: 20px;
}

.counter {
  width: 1rem;
  height: 1rem;
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 5px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -10px;
  right: -5px;
}
.dropdown-toggle::after {
  display: none !important;
}
.notifications {
  padding-left: 1rem;
  position: relative;
}
.username {
  padding-left: 1rem;
  position: relative;
}
#userNotifications {
  color: white;
}
.header__notification__item {
  border-style: none none solid none;
  border-color: var(--main-color);
  border-width: 0.05rem;
  padding-top: 0.5rem;
}
.header__notification__item__date {
  font-size: small;
  color: gray;
}
.header__notification__item__message {
  margin-bottom: 0.5rem;
}
