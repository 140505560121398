.jobs__list {
  /* padding-right: 20px;
    margin-top: 20px; */
  background-color: white;
  border-radius: 0.8rem;
}

.jobs__list__content{
    padding: 20px;
}
.job__link{
  text-decoration: none;
  color: black;
}

.job__link:hover{
  color: black;
  text-decoration: underline;
}