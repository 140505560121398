.load{
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0px;
    position:fixed;
    background-color: #f8f9fa00;
}

.reactLoad{
   margin-left: 50%;
   margin-right: 50%;
   margin-top: 10%;
   margin-bottom: 20%;
}
.alert{
    text-align: center;
    width: 54%;
    margin-top: 41%;
    margin-left: 40%;
    margin-right: 40%;
}