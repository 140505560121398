.main {
  width: 100%;
  height: 200%;
  overflow: hidden;
  background-color: #f7f7f7;
  margin-top: 5rem;
}
.user__profile__content {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.user__profile__userdetails {
  margin-top: 20px;
  width: 90%;
  background-color: white;
}
