.transition-3 {
  transition: all 0.33s ease;
  -webkit-transition: all 0.33s ease;
  -moz-transition: all 0.33s ease;
}

.transition-5 {
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
}

/* @mixin fancy-scrollbar() {
    &::-webkit-scrollbar {
        width: $scrollbar-width;
    }
    &::-webkit-scrollbar-track {
        border-radius: $scrollbar-width;
        background-color: rgba(25, 147, 147, 0.1);
    }
    &::-webkit-scrollbar-thumb {
        border-radius: $scrollbar-width;
        background-color: $chat-thread-bgd-color;
    }
}

@mixin scrolling-shadows($background-color: transparent, $shadow-intensity: 0.5, $shadow-color: $default-shadow-color, $cover-size: 40px, $shadow-size: 14px) {
  
    background: linear-gradient($background-color 30%, rgba($background-color, 0)), linear-gradient(rgba($background-color, 0), $background-color 70%) 0 100%, radial-gradient(50% 0, farthest-side, rgba($shadow-color, $shadow-intensity), rgba($shadow-color, 0)), radial-gradient(50% 100%, farthest-side, rgba($shadow-color, $shadow-intensity), rgba($shadow-color, 0)) 0 100%;
    background: linear-gradient($background-color 30%, rgba($background-color, 0)), linear-gradient(rgba($background-color, 0), $background-color 70%) 0 100%, radial-gradient(farthest-side at 50% 0, rgba($shadow-color, $shadow-intensity), rgba($shadow-color, 0));
  
    background-repeat: no-repeat;
    background-color: $background-color;
    background-size: 100% $cover-size, 100% $cover-size, 100% $shadow-size, 100% $shadow-size;
    
    background-attachment: local, local, scroll, scroll;
} */
/* li.other {
    /* width: 50px;
    height: 50px; */
/* animation: show-chat-odd 0.15s 1 ease-in;
    -moz-animation: show-chat-odd 0.15s 1 ease-in;
    -webkit-animation: show-chat-odd 0.15s 1 ease-in;
    float: right;
    margin-right: 45px;
    color: #0AD5C1; */
/* position: absolute; */
/* } */

/* li.other:before {
    right: -45px;

    background-image: url(https://github.com/Thatkookooguy.png);
}

li.other:after {
    border-right: 20px solid transparent;
    right: -10px;
} */

/* li.self { */
/* width: 50px;
    height: 50px; */
/* animation: show-chat-even 0.15s 1 ease-in;
    -moz-animation: show-chat-even 0.15s 1 ease-in;
    -webkit-animation: show-chat-even 0.15s 1 ease-in;
    float: left;
    margin-left: 45px; */
/* position: relative; */
/* color: #0EC879;
    top: 300px;
} */

/* li.self:before {
    left: -45px;

    background-image: url(https://github.com/ortichon.png);
}

li.self:after {
    border-left: 20px solid transparent;
    left: -10px;
} */

/* .messages {
    padding: 10px;
    margin: 0;
    margin-bottom: 100px;
    list-style: none;
    /* overflow-y: scroll; */
/* overflow-x: scroll;
    flex-grow: 1;
    border-radius: 4px;
    background: transparent; */
/* @include fancy-scrollbar; */
/* } */

/* .messages li {
    position: relative;
    clear: both;
    display: inline-block;
    padding: 14px;
    margin: 0 0 20px 0;
    font: 12px/16px 'Noto Sans', sans-serif;
    border-radius: 10px;
    background-color: rgba(25, 147, 147, 0.2);
    word-wrap: break-word;
    max-width: 81%;
} */

/* .messages li:before {
    position: absolute;
    top: 0;
    width: 25px;
    height: 25px;
    border-radius: 25px;
    content: '';
    background-size: cover;
}

.messages li::after {
    position: absolute;
    top: 10px;
    content: '';
    width: 0;
    height: 0;
    border-top: 10px solid rgba(25, 147, 147, 0.2);
    ;
}


@keyframes show-chat-even {
    0% {
        margin-left: -480px;
    }

    100% {
        margin-left: 0;
    }
}

@-moz-keyframes show-chat-even {
    0% {
        margin-left: -480px;
    }

    100% {
        margin-left: 0;
    }
}

@-webkit-keyframes show-chat-even {
    0% {
        margin-left: -480px;
    }

    100% {
        margin-left: 0;
    }
}

@keyframes show-chat-odd {
    0% {
        margin-right: -480px;
    }

    100% {
        margin-right: 0;
    }
}

@-moz-keyframes show-chat-odd {
    0% {
        margin-right: -480px;
    }

    100% {
        margin-right: 0;
    }
}

@-webkit-keyframes show-chat-odd {
    0% {
        margin-right: -480px;
    }

    100% {
        margin-right: 0;
    }
} */

.chat {
  width: 100%;
  /* height: 100%; */
  margin-top: auto;
  margin-bottom: auto;
  overflow-y: scroll;
}
.card {
  height: 382px;
  border: none;
  background-color: #f8f9fa00 !important;
}
/* .contacts_body{
    padding:  0.75rem 0 !important;
    overflow-y: auto;
    white-space: nowrap;
} */
.msg_card_body {
  /* overflow-y: auto; */
}
/* .card-header{
    border-radius: 15px 15px 0 0 !important;
    border-bottom: 0 !important;
} */
.card-footer {
  border-radius: 0 0 15px 15px !important;
  border-top: 0 !important;
}

/* .search{
    border-radius: 15px 0 0 15px !important;
    background-color: rgba(0,0,0,0.3) !important;
    border:0 !important;
    color:white !important;
} */
/* .search:focus{
     box-shadow:none !important;
   outline:0px !important;
} */
.type_msg {
  background-color: #f8f9fa00 !important;
  border: 0 !important;
  color: rgb(14, 11, 11) !important;
  height: 60px !important;
  overflow-y: auto;
}
.type_msg:focus {
  box-shadow: none !important;
  outline: 0px !important;
}
/* .attach_btn{
border-radius: 15px 0 0 15px !important;
background-color: rgba(0,0,0,0.3) !important;
    border:0 !important;
    color: white !important;
    cursor: pointer;
} */
.send_btn {
  border-radius: 0 9px 15px 0 !important;
  background-color: #f8f9fa00 !important;
  border: 0 !important;
  color: rgb(21, 11, 11) !important;
  cursor: pointer;
  height: 61px;
}
/* .search_btn{
    border-radius: 0 15px 15px 0 !important;
    background-color: rgba(0,0,0,0.3) !important;
    border:0 !important;
    color: white !important;
    cursor: pointer;
} */
/* .contacts{
    list-style: none;
    padding: 0;
} */
/* .contacts li{
    width: 100% !important;
    padding: 5px 10px;
    margin-bottom: 15px !important;
} */
/* .active{
    background-color: rgba(0,0,0,0.3);
} */
.user_img {
  height: 70px;
  width: 70px;
  border: 1.5px solid #f5f6fa;
}
.user_img_msg {
  height: 40px;
  width: 40px;
  border: 1.5px solid #f5f6fa;
}
.img_cont {
  position: relative;
  height: 70px;
  width: 70px;
}
.img_cont_msg {
  height: 40px;
  width: 40px;
}
/* .online_icon{
position: absolute;
height: 15px;
width:15px;
background-color: #4cd137;
border-radius: 50%;
bottom: 0.2em;
right: 0.4em;
border:1.5px solid white;
} */
/* .offline{
background-color: #c23616 !important;
} */
/* .user_info{
margin-top: auto;
margin-bottom: auto;
margin-left: 15px;
} */
/* .user_info span{
font-size: 20px;
color: white;
} */
/* .user_info p{
font-size: 10px;
color: rgba(255,255,255,0.6);
} */
/* .video_cam{
margin-left: 50px;
margin-top: 5px;
}
.video_cam span{
color: white;
font-size: 20px;
cursor: pointer;
margin-right: 20px;
} */
.msg_cotainer {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
  border-radius: 25px;
  background-color: #82ccdd;
  padding: 10px;
  position: relative;
}
.msg_cotainer_send {
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 10px;
  border-radius: 25px;
  background-color: #78e08f;
  padding: 10px;
  position: relative;
}
.msg_time {
  position: absolute;
  left: 0;
  bottom: -15px;
  color: rgba(7, 5, 5, 0.5);
  font-size: 10px;
  display: contents;
}
.msg_creator {
  position: absolute;
  left: 0;
  bottom: -15px;
  color: var(--main-color-dark-2);
  font-size: 10px;
  display: contents;
  font-weight: bolder;
}
.msg_time_send {
  position: absolute;
  right: 0;
  bottom: -15px;
  color: rgba(13, 4, 4, 0.5);
  font-size: 10px;
  display: contents;
}
/* .msg_head{
position: relative;
} */
/* #action_menu_btn{
position: absolute;
right: 10px;
top: 10px;
color: white;
cursor: pointer;
font-size: 20px;
} */
/* .action_menu{
z-index: 1;
position: absolute;
padding: 15px 0;
background-color: rgba(0,0,0,0.5);
color: white;
border-radius: 15px;
top: 30px;
right: 15px;
display: none;
}
.action_menu ul{
list-style: none;
padding: 0;
margin: 0;
}
.action_menu ul li{
width: 100%;
padding: 10px 15px;
margin-bottom: 5px;
}
.action_menu ul li i{
padding-right: 10px;

}
.action_menu ul li:hover{
cursor: pointer;
background-color: rgba(0,0,0,0.2);
}
@media(max-width: 576px){
.contacts_card{
margin-bottom: 15px !important;
}
} */

.icon-style {
  width: 100%;
  height: 72%;

  margin-top: 9px;
  color: var(--main-color);
}
