.change__password__form {
  width: 40%;
  margin: 10px auto;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  margin-top: 123px;
}
.change__password__FormGroup {
  margin: 15px;
}
