.user__profile__container {
  margin-top: 1.5rem;
}
.userProfileImage .userimg {
  border-radius: 50%;
}
.content {
  justify-items: center;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.disabled__content {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
