.userdetail {
  font-weight: 600;
  padding: 20px;
}
.userdetail img {
  border-radius: 50%;
}
.userdetail .userinfo {
  background-color: white;
  padding: 10px;
  line-height: 40px;
  border-radius: 0.8rem;
}
.userdetail .userinfo .line {
  width: 100%;
  height: 1px;
  background-color: #e7e8e9;
}
