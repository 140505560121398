.forgotPasswordVerificationForm {
  width: 40%;
  margin: 10px auto;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  margin-top: 30px;
}
.forgotPasswordVerificationGroup {
  margin: 15px;
}

