ul {
  display: flex;
  margin-top: 13px;
  list-style: none;
}
ul li {
  padding-left: 14px;
  /* margin-left: 9px; */
}
ul li a {
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  font-family: "Helvetica";
}
.bgcolor {
  background-color: white;
  min-height: 400px;
  margin-bottom: 100px;
  clear: both;
}

.nbv {
  display: flex;
}
.tworowborder {
  border-style: groove;
  border-color: darkgray;
  border-width: 1px;
}
.borderonerow {
  border-right: groove;
  border-color: darkgray;
  border-width: 1px;
  left: 10px;
}
.radiobtn {
  line-height: 30px;
}

.error__lable{
  color: red;
}

.is-danger{
  border-color: red !important;
}

.upload{
  margin-top: 200px;
}
.btn__icon__margen{
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
/* html {
  --main-color: #D13636;
  --main-color-dark: #bb2828;
  --main-color-dark-1: #a81c1c;
  --main-color-dark-2: #a11313;
} */
html {
  --main-color: #52EDC7;
  --main-color-dark: #5AC8FB;
  --main-color-dark-1: #07acf9;
  --main-color-dark-2: #0581bb;
}
.btn-outline {
  --bs-btn-color: var(--main-color) !important;
  --bs-btn-border-color: var(--main-color) !important;
  --bs-btn-hover-color: #fff !important;
  --bs-btn-hover-bg: var(--main-color) !important;
  --bs-btn-hover-border-color: var(--main-color) !important;
  --bs-btn-focus-shadow-rgb: 25,135,84 !important;
  --bs-btn-active-color: #fff !important;
  --bs-btn-active-bg: var(--main-color) !important;
  --bs-btn-active-border-color: var(--main-color) !important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
  --bs-btn-disabled-color: var(--main-color) !important;
  --bs-btn-disabled-bg: transparent !important;
  --bs-btn-disabled-border-color: var(--main-color) !important;
  --bs-gradient: none !important;
}

.btn-success-c {
  --bs-btn-color: #fff !important;
  --bs-btn-bg: var(--main-color) !important;
  --bs-btn-border-color: var(--main-color) !important;
  --bs-btn-hover-color: #fff !important;
  --bs-btn-hover-bg: var(--main-color-dark) !important;
  --bs-btn-hover-border-color: var(--main-color-dark-1) !important;
  --bs-btn-focus-shadow-rgb: 60,153,110 !important;
  --bs-btn-active-color: #fff !important;
  --bs-btn-active-bg: var(--main-color-dark-1) !important;
  --bs-btn-active-border-color: var(--main-color-dark-2) !important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
  --bs-btn-disabled-color: #fff !important;
  --bs-btn-disabled-bg: var(--main-color) !important;
  --bs-btn-disabled-border-color: var(--main-color) !important;
}


/* .btn-success {
  --bs-btn-color: #fff;
  --bs-btn-bg: #198754;
  --bs-btn-border-color: #198754;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #157347;
  --bs-btn-hover-border-color: #146c43;
  --bs-btn-focus-shadow-rgb: 60,153,110;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #146c43;
  --bs-btn-active-border-color: #13653f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #198754;
  --bs-btn-disabled-border-color: #198754;
} */
