.logonform {
  width: 40%;
  margin: 10px auto;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  margin-top: 5rem;
}
.loinformgroup {
  margin: 15px;
}
.forgotpassword{
  text-align: start;
  margin: 1rem;
}
.popUpInput{
  border: none;
  text-align: center;
  width: 100%;
  word-wrap: break-word;
  word-break: break-all;
}
