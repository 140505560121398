.join__requests__main {
  width: 100%;
  overflow: hidden;
  background-color: #f7f7f7;
}
.join__requests__content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 65px;

}
.join__requests__userdetails{
  flex: 1;
}
.join__requests__jobsList {
  flex: 3;
  padding-right: 20px;
  margin-top: 20px;
}