.create__user__form {
  width: 40%;
  margin: 0px auto;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.create__user {
  background-color: white;
}
.create__user__info__form__group {
  margin: 15px;
  text-align: initial;
}
