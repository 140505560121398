.join__request__listing {
  display: flex;
  flex-direction: row;
  border-style: solid;
  border-color: #e7e8e9;
  border-width: 0.11rem;
  margin-bottom: 20px;
}
.join__request__listing__content {
  display: flex;
  flex: 3;
  flex-direction: column;
  margin-top: 1rem;
  margin-left: 1rem;
}
.join__request__listing__content__descripttion p {
  overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.join__request__listing__accept {
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-top: 1rem;
  margin-left: 1rem;
  align-items: center;
  justify-content: space-evenly;
}

