.jobdetails {
  display: flex;
  flex-direction: column;
  background-color: white;
}
.jobdetails__content {
  background-color: white;
  margin: 20px;
}
.jobdetails__image {
  width: 100%;
  height: 100%;
  margin: 1.5rem;
  display: flex;
  flex-direction: column;
}
.jobdetails__owner{
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
.jobdetails__image__preview {
  width: 100%;
  height: 30rem;
}

.jobdetails__info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1.5rem;
}
.tag {
  border-style: groove;
  border-color: var(--main-color);
  border-width: 2px;
  width: 100px;
  color: var(--main-color);
  font-weight: bold;
  border-radius: 10px;
  text-align: center;
}
.jobdetails__info__tags {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;
  flex: 1;
}
.jobdetails__info__inputs {
  flex: 3;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: var(--main-color);
}

.nav-link {
  color: var(--main-color);
}
.jobdetails__tab__content {
  border-style: solid none none none;
  border-color: var(--main-color);
  border-width: 0.15rem;
  padding: 1rem;
}
.jobdetails__apply {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-items: center;
  margin-top: 20px;
}
.jobdetails__apply h1 {
  text-align: center;
}
.line {
  width: 100%;
  height: 3px;
  background-color: var(--main-color);
}
.jobdetails__footer{
    display: flex;
    flex-direction: row-reverse;
    margin-top: 10px;
    align-items: flex-end;
    justify-items: end;
}
.jobdetails__footer__content{
    align-items: flex-end;
    justify-items: end;
    padding: 10px;
}
.jobdetails__disabled__content {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.jobdetails__round__image {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  cursor: pointer;
  object-fit: cover;
}
.jobdetails__actions{
  display: flex;
  flex-direction: row-reverse;
}
