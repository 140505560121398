.join__request__details {
  display: flex;
  flex-direction: column;
  background-color: white;
}
.join__request__details__content {
  background-color: white;
  margin: 20px;
}
.join__request__details__footer{
    display: flex;
    flex-direction: row-reverse;
    margin-top: 10px;
    align-items: flex-end;
    justify-items: end;
}
.join__request__details__footer__content{
    align-items: flex-end;
    justify-items: end;
    padding: 10px;
}
.join__request__details__disabled__content {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}