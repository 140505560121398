.attachment__list__disabled__content {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.attachment__list__file__name {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.attachment__list__disabled__content a {
text-decoration: none;
}

.attachment__list__file {
  display: flex;
  flex-direction: row;
}
