.job__listing {
  display: flex;
  flex-direction: row;
  border-style: solid;
  border-color: #e7e8e9;
  border-width: 0.11rem;
  margin-bottom: 20px;
}
.job__listing__content {
  display: flex;
  flex: 4;
  flex-direction: column;
  margin-top: 1rem;
  margin-left: 1rem;
}
.job__listing__content__tags {
  display: flex;
  flex-direction: row;
}
.tag__item{
  padding: 0.25rem;
}
.tag  {
  border-style: groove;
  border-color: var(--main-color);
  border-width: 2px;
  width: 100px;
  color: var(--main-color);
  font-weight: bold;
  border-radius: 10px;
  text-align: center;
  margin: 0.25rem;
}
.job__listing__image {
  flex: 1;
}
.job__listing__image img {
  width: 100%;
  height: 100%;
}
.job__listing__content__descripttion p {
  overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
