.about__us__about__content {
  display: flex;
  flex-direction: row;
}
.about__us__about__img {
  flex: 1;
}
.about__us__about__img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.about__us__about__text {
  display: flex;
  flex: 4;
  flex-direction: column;
  margin-top: 1rem;
  margin-left: 1rem;
}
