.join__request__Info__main {
  width: 100%;
  overflow: hidden;
  background-color: #f7f7f7;
}
.join__request__Info__content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 5rem;
}
.join__request__Info__userdetails {
  flex: 1;
}
.join__request__Info__jobdetails {
  flex: 3;
  padding-right: 20px;
  margin-top: 20px;
}
