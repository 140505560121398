.joinform {
  margin: 10px auto;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
  margin-top: 5rem;
}
.join__user__type {
  display: flex;
  flex-direction: row;
}
.join__user__type__check {
  padding: 1.5rem;
}
.join__content {
  justify-items: center;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.join__container {
  margin-top: 1.5rem;
}
.join__form__select{
  margin-bottom: 1rem;
}
