.join__requests__list {
  /* padding-right: 20px;
    margin-top: 20px; */
  background-color: white;
  border-radius: 0.8rem;
}

.join__requests__list__content {
  padding: 20px;
}
.join__requests__header {
  margin: 1rem;
}
