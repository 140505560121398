.user__jobs {
  /* padding-right: 20px;
    margin-top: 20px; */
  background-color: white;
  border-radius: 0.8rem;
}

.user__jobs__content{
    padding: 20px;
    display: flex;
    flex-direction: column;
}
.user__tasks__header{
  margin: 1rem;
}
.createButton{
  background-color: var(--main-color);
  color: white;
  border-radius: 5%;
  border: none;
  padding: 5px,5px,10px,20px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
}