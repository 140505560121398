.slider {
  margin-top: -99px;
}
.slider .carousel-indicators {
  display: none;
}
.slider .carousel-control-next {
  display: none;
}
.slider .carousel-control-prev {
  display: none;
}
.slider__content {
  margin-top: 12rem;
  margin-bottom: 12rem;
}
.slider__button {
  z-index: 12;
  background: linear-gradient(rgb(82, 237, 199) 0%, rgb(90, 200, 251) 100%);
  height: auto;
  width: auto;
  color: rgb(0, 0, 0);
  text-decoration: none;
  white-space: nowrap;
  min-height: 0px;
  min-width: 0px;
  max-height: none;
  max-width: none;
  text-align: left;
  line-height: 50px;
  letter-spacing: 0px;
  font-weight: 500;
  font-size: 18px;
  padding-left: 40px;
  border-radius: 5px;
  margin-right: 30px;
  padding-right: 40px;
  filter: blur(0px) grayscale(0%) brightness(100%);
  transform-origin: 50% 50%;
  opacity: 1;
  transform: translate(0px, 0px);
  visibility: visible;
  border-width: 0px;
  border-color: rgb(0, 0, 0);
}
