.copy-right-style {
  margin-left: 12px;
  color: white;
}

.footer-style {
  bottom: 0px;
  width: 100%;
  background-color: #5d1a94;
  position: fixed;
}

.font-weight-bold {
  color: white;
}

#market-link {
  text-decoration: none;
}
