.about__us {
  margin-top: 5rem;
}
.about__us__header {
  color: var(--main-color);
  margin-bottom: 2rem;
}
.about__us__slider .carousel-indicators {
  display: none;
}
.about__us__slider .carousel-control-next {
  display: none;
}
.about__us__slider .carousel-control-prev {
  display: none;
}
.about__us__ownners__slider {
  font-family: sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
}
.about__us__ownners__slider img {
  width: 70%;
  height: 70%;
  object-fit: contain;
}
.about__us__slider {
  font-family: sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.about__us__line {
  border-top: 8px solid var(--main-color);
  border-radius: 5px;
}
